<template>
  <v-row :key="`${step.id}-properties`" no-gutters>
    <v-col cols="12">
      <v-form
        ref="form"
        v-model="valid"
      >
        <component
          :is="getStepComponent(type, subType).component"
          :key="`${step.id}-properties`"
          :step="step"
          :can-edit="canEdit"
          v-bind="getStepComponent(type, subType).additionalProps"
          @changeStepProperties="properties = $event"
          @openEditor="handleOpenEditorFromStep"
          @openDateTimeModal="handleOpenDateTimeModalFromStep"
        />
      </v-form>
    </v-col>
    <v-dialog v-if="showEditor" v-model="showEditor" max-width="71%">
      <editor
        :item="selectedItem"
        :language="language"
        :vars="steps"
        :step="step"
        @closeDialog="showEditor = false; selectedItem = null"
        @fromEditor="updateFromEditor($event)"
      ></editor>
    </v-dialog>
    <v-dialog v-if="showGlobalEditor" v-model="showGlobalEditor" max-width="71%">
      <global-editor
        :title="$lang.labels.isBodyScript"
        :item="selectedItem"
        :with-buttons="true"
        @closeDialog="showGlobalEditor = false; selectedItem = null"
        @fromGlobalEditor="updateFromEditor($event)"
      ></global-editor>
    </v-dialog>
    <v-dialog v-if="showSlackEditor" v-model="showSlackEditor" min-width="90%">
      <slack-message-editor
        :item="selectedItem"
        :can-edit="canEdit"
        @closeDialog="showSlackEditor = false; selectedItem = null"
        @fromEditor="updateFromEditor($event)"
      ></slack-message-editor>
    </v-dialog>
    <v-dialog v-if="showDateTimeModal" v-model="showDateTimeModal" max-width="340">
      <v-card>
        <date-time-modal
          :data="dateTimeSelectedObject"
          :can-edit="canEdit"
          @closeDialog="showDateTimeModal = false; dateTimeSelectedObject = null"
          @dataChanged="updateFromDataTimeModal($event)"
        ></date-time-modal>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { getNestedValue, objectsEqual, formatDateTimeToISO, deepMerge } from '@/utils/helpers'
import editor from './Editor'
import globalEditor from '../../components/ui/GlobalEditor'
import SlackMessageEditor from './SlackMessageEditor'
import { mapActions } from 'vuex'
import DateTimeModal from './DateTimeModal.vue'

export default {
  components: {
    JdbcStep: () => import('./steps/JdbcStep'),
    MongodbStep: () => import('./steps/MongodbStep'),
    ForeachStep: () => import('./steps/ForeachStep'),
    ExecuteProcessStep: () => import('./steps/ExecuteProcessStep'),
    JSStep: () => import('./steps/JSStep'),
    GroovyStep: () => import('./steps/GroovyStep'),
    SwitchStep: () => import('./steps/SwitchStep'),
    EmailStep: () => import('./steps/EmailStep'),
    RestStep: () => import('./steps/RestStep'),
    JWTStep: () => import('./steps/JWTStep'),
    QueryStep: () => import('./steps/QueryStep'),
    WhileStep: () => import('./steps/WhileStep'),
    SlackStep: () => import('./steps/SlackStep'),
    ProcessSettingStep: () => import('./steps/ProcessSettingStep'),
    UnsetVarsStep: () => import('./steps/UnsetVarsStep'),
    ExecuteExternalCmdStep: () => import('./steps/ExecuteExternalCmdStep'),
    UUIDStep: () => import('./steps/UUIDStep'),
    LogStep: () => import('./steps/LogStep'),
    XSLTStep: () => import('./steps/XSLTStep'),
    EscCharsStep: () => import('./steps/EscCharsStep'),
    TwilioStep: () => import('./steps/TwilioStep'),
    UserStep: () => import('./steps/UserStep'),
    PluginStep: () => import('./steps/PluginStep'),
    S3Step: () => import('./steps/S3Step'),
    SecurityStep: () => import('./steps/SecurityStep'),
    IMAPStep: () => import('./steps/IMAPStep'),
    PdfStep: () => import('./steps/PdfStep'),
    CsvStep: () => import('./steps/CsvStep'),
    ImageStep: () => import('./steps/ImageStep'),
    MessagingStep: () => import('./steps/MessagingStep'),
    PaymentSensePacStep: () => import('./steps/PaymentSensePacStep'),
    PaymentSenseConnectEStep: () => import('./steps/PaymentSenseConnectEStep'),
    QRStep: () => import('./steps/QRStep'),
    XLSStep: () => import('./steps/XLSStep'),
    FTPStep: () => import('./steps/FTPStep'),
    CredentialStep: () => import('./steps/CredentialStep'),
    QueryBuilderStep: () => import('./steps/QueryBuilderStep'),
    ZipStep: () => import('./steps/ZipStep'),
    EncoderStep: () => import('./steps/EncoderStep'),
    StorageStep: () => import('./steps/StorageStep'),
    FormattingStep: () => import('./steps/FormattingStep'),
    PythonStep: () => import('./steps/PythonStep'),
    GraphQLStep: () => import('./steps/GraphQLStep'),
    XMLStep: () => import('./steps/XMLStep'),
    OCRStep: () => import('./steps/OCRStep'),
    ChatGptStep: () => import('./steps/ChatGptStep'),
    SSHStep: () => import('./steps/SSHStep'),
    LDAPStep: () => import('./steps/LDAPStep'),
    YAMLStep: () => import('./steps/YAMLStep'),
    ICalendarStep: () => import('./steps/ICalendarStep'),
    ExceptionStep: () => import('./steps/ExceptionStep'),
    TryOrFinallyStep: () => import('./steps/TryOrFinallyStep'),
    CloudFrontStep: () => import('./steps/CloudFrontStep'),
    OutlookCalendarStep: () => import('./steps/OutlookCalendarStep'),
    TimerStep: () => import('./steps/TimerStep'),

    editor,
    globalEditor,
    SlackMessageEditor,
    DateTimeModal
  },
  props: {
    step: {
      type: Object,
      default: () => {
        return null
      }
    },
    steps: {
      type: Array,
      default: () => []
    },
    isNewSelected: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    filteredExceptions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resourceIsSystem: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showDateTimeModal: false,
    dateTimeSelectedObject: null,
    language: '',
    showEditor: false,
    showJsGroovyEditor: false,
    selectedItem: null,
    lock: false,
    valid: false,

    stepData: {},
    properties: {},
    type: '',
    subType: '',
    showGlobalEditor: false,
    isBodyScript: false,
    showSlackEditor: false
  }),
  watch: {
    step: {
      handler(val, oldval) {
        if (val && (!oldval || !objectsEqual(val, oldval))) {
          this.init()
        }
      },
      deep: true,
      immediate: true
    },
    properties: {
      handler() {
        if (!this.lock) {
          if (this.properties.childEventIdPath === '') {
            this.properties.childEventIdPath = null
          }

          if (this.step.type) {
            this.stepData.properties = this.properties
          }
          if (this.subType) {
            this.stepData = this.properties
          }

          // Keeping it for now in case something breaks after refactoring
          // this.$emit('saveSelected', this.stepData)

          //TODO rough solution - possibly may be updated
          if (this.subType === 'QUERY') {
            this.step.name = this.properties.name
            this.step.query = this.properties.query
          }
          if (this.subType === 'EXCEPTION') {
            this.step.exceptions = this.properties.exceptions
            this.step.name = this.properties.name
          }
        }
      },
      deep: true
    }

  },
  created() {
    if (!this.isNewSelected) {
      this.init()
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    getStepComponent(stepType, stepSubType) {
      const stepTypeMap = {
        'JDBC': {
          component: 'JdbcStep',
          additionalProps: {}
        },
        'MONGODB': {
          component: 'MongodbStep',
          additionalProps: {}
        },
        'FOREACH': {
          component: 'ForeachStep',
          additionalProps: {}
        },
        'EXECUTE_PROCESS': {
          component: 'ExecuteProcessStep',
          additionalProps: { resourceIsSystem: this.resourceIsSystem }
        },
        'JS': {
          component: 'JSStep',
          additionalProps: { steps: this.steps }
        },
        'GROOVY': {
          component: 'GroovyStep',
          additionalProps: { steps: this.steps }
        },
        'SWITCH': {
          component: 'SwitchStep',
          additionalProps: {}
        },
        'EMAIL': {
          component: 'EmailStep',
          additionalProps: {}
        },
        'REST': {
          component: 'RestStep',
          additionalProps: { steps: this.steps }
        },
        'JWT': {
          component: 'JWTStep',
          additionalProps: {}
        },
        'WHILE': {
          component: 'WhileStep',
          additionalProps: {}
        },
        'SLACK': {
          component: 'SlackStep',
          additionalProps: {}
        },
        'PROCESS_SETTING': {
          component: 'ProcessSettingStep',
          additionalProps: {}
        },
        'UNSET_VARIABLES': {
          component: 'UnsetVarsStep',
          additionalProps: {}
        },
        'EXECUTE_EXTERNAL_COMMAND': {
          component: 'ExecuteExternalCmdStep',
          additionalProps: {}
        },
        'UUID': {
          component: 'UUIDStep',
          additionalProps: {}
        },
        'LOG': {
          component: 'LogStep',
          additionalProps: {}
        },
        'XSLT': {
          component: 'XSLTStep',
          additionalProps: {}
        },
        'ESC_CHARS': {
          component: 'EscCharsStep',
          additionalProps: {}
        },
        'TWILIO': {
          component: 'TwilioStep',
          additionalProps: {}
        },
        'USER': {
          component: 'UserStep',
          additionalProps: {}
        },
        'PLUGIN': {
          component: 'PluginStep',
          additionalProps: {}
        },
        'S3': {
          component: 'S3Step',
          additionalProps: {}
        },
        'SECURITY': {
          component: 'SecurityStep',
          additionalProps: {}
        },
        'IMAP': {
          component: 'IMAPStep',
          additionalProps: {}
        },
        'PDF': {
          component: 'PdfStep',
          additionalProps: {}
        },
        'CSV': {
          component: 'CsvStep',
          additionalProps: {}
        },
        'IMAGE': {
          component: 'ImageStep',
          additionalProps: {}
        },
        'MESSAGING': {
          component: 'MessagingStep',
          additionalProps: {}
        },
        'PAYMENT_SENSE_PAC': {
          component: 'PaymentSensePacStep',
          additionalProps: {}
        },
        'PAYMENT_SENSE_CONNECT_E': {
          component: 'PaymentSenseConnectEStep',
          additionalProps: {}
        },
        'QR': {
          component: 'QRStep',
          additionalProps: {}
        },
        'XLS': {
          component: 'XLSStep',
          additionalProps: {}
        },
        'FTP': {
          component: 'FTPStep',
          additionalProps: {}
        },
        'CREDENTIAL': {
          component: 'CredentialStep',
          additionalProps: {}
        },
        'QUERY_BUILDER': {
          component: 'QueryBuilderStep',
          additionalProps: { steps: this.steps }
        },
        'ZIP': {
          component: 'ZipStep',
          additionalProps: {}
        },
        'ENCODER': {
          component: 'EncoderStep',
          additionalProps: {}
        },
        'STORAGE': {
          component: 'StorageStep',
          additionalProps: {}
        },
        'FORMATTING': {
          component: 'FormattingStep',
          additionalProps: {}
        },
        'PYTHON': {
          component: 'PythonStep',
          additionalProps: { steps: this.steps }
        },
        'GRAPHQL': {
          component: 'GraphQLStep',
          additionalProps: { steps: this.steps }
        },
        'XML': {
          component: 'XMLStep',
          additionalProps: {}
        },
        'OCR': {
          component: 'OCRStep',
          additionalProps: { lock: this.lock }
        },
        'CHAT_GPT': {
          component: 'ChatGptStep',
          additionalProps: {}
        },
        'SSH': {
          component: 'SSHStep',
          additionalProps: {}
        },
        'LDAP': {
          component: 'LDAPStep',
          additionalProps: { steps: this.steps }
        },
        'YAML': {
          component: 'YAMLStep',
          additionalProps: {}
        },
        'I_CALENDAR': {
          component: 'ICalendarStep',
          additionalProps: { steps: this.steps }
        },
        'TRY_CATCH': {
          component: null
        },
        'CLOUD_FRONT': {
          component: 'CloudFrontStep',
          additionalProps: {}
        },
        'OUTLOOK_CALENDAR': {
          component: 'OutlookCalendarStep',
          additionalProps: {}
        },
        'TIMER': {
          component: 'TimerStep',
          additionalProps: {}
        },
        'DO_WHILE': {
          component: 'WhileStep',
          additionalProps: {}
        }
      }

      const stepSubTypeMap = {
        'QUERY': {
          component: 'QueryStep',
          additionalProps: {}
        },
        'EXCEPTION': {
          component: 'ExceptionStep',
          additionalProps: { filteredExceptions: this.filteredExceptions }
        },
        'TRY': {
          component: 'TryOrFinallyStep',
          additionalProps: {}
        },
        'FINALLY': {
          component: 'TryOrFinallyStep',
          additionalProps: {}
        }
      }

      return stepSubType ? stepSubTypeMap[stepSubType]
        : stepType ? stepTypeMap[stepType]
          : null
    },

    handleOpenDateTimeModalFromStep(key) {
      this.dateTimeSelectedObject = { value: getNestedValue(this.properties, key), target: key }
      this.showDateTimeModal = true
    },
    updateFromDataTimeModal(data) {
      const targetPath = data.target.split('.')
      let target = this.properties

      for (let i = 0; i < targetPath.length - 1; i++) {
        const key = targetPath[i]

        if (!target[key]) {
          this.$set(target, key, {})
        }

        target = target[key]
      }

      this.$set(target, targetPath[targetPath.length - 1], formatDateTimeToISO(data.value))
    },
    handleOpenEditorFromStep(event, type, itemForSelect = null, language = null) {
      if (itemForSelect) {
        this.selectedItem = { ...itemForSelect }
        if (type === 'message') {
          this.showSlackEditor = true
        } else {
          this.showEditor = true
        }

        return
      } else {
        this.openEditor(event, type, language)
      }
    },
    openEditor(event, type, language = null) {
      if (language)
        this.language = language
      if (type === 'bodyScript') {
        this.selectedItem = event
        this.isBodyScript = true
        this.showGlobalEditor = true

        return
      }
      if (event.text) {
        this.selectedItem = { ...event, parentType: type }
        this.showEditor = true
      } else {
        this.$emit('showSnack', { text: this.$lang.hints.addKeyFirst, type: 'warning' })
      }
    },
    updateFromEditor(data) {
      if (this.isBodyScript) {
        this.properties.bodyScript = data
        this.isBodyScript = false

        return
      }
      if (data.text) {
        this.properties[data.parentType][data.text] = data.value
      } else {
        this.properties[data.parentType] = data.value
      }
    },
    init() {
      this.type = ''
      this.subType = ''
      this.lock = true

      if (this.step && this.step.type) {
        const newProperties = deepMerge(this.properties, this.step.properties)

        this.step.properties = { ...newProperties }

        this.stepData = this.step

        this.type = this.step.type
      }

      if (this.step && this.step.subType && this.step.subType !== 'TRY' && this.step.subType !== 'FINALLY' && this.step.subType !== 'EXCEPTION') {
        const newProperties = deepMerge(this.properties, this.step)

        this.step.properties = { ...newProperties }
        this.stepData = this.step

      }
      if (this.step && this.step.subType) {
        this.subType = this.step.subType
      }

      setTimeout(() => {
        this.lock = false
      }, 50)
    }
  }
}
</script>
<style lang="scss" scoped>
.button-help {
  opacity: 0.6;
}

.button-help:hover {
  opacity: 1;
}
</style>
