<template>
  <v-card class="pa-2">
    <v-card-title class="pb-0">
      <span>{{ $lang.labels.message }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        <v-icon dark size="20" >mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle class="pt-1">
      {{ $lang.hints.slackMessage }}
    </v-card-subtitle>
    <v-textarea
      v-model="code"
      outlined
      dense
      data-cy="code-message"
      :label="$lang.labels.message"
      :rules="[(v) => (v && v.length < 3073) || $lang.errors.slackMessage]"
      counter
      :readonly="!canEdit"
    ></v-textarea>
    <v-card-title class="pt-2">
      <v-btn
        color="primary"
        outlined
        @click="$emit('closeDialog', true)"
      >
        <v-icon left dark class="mr-1">
          mdi-cancel
        </v-icon>
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        @click="save()"
      >
        <v-icon left dark class="mr-1">
          mdi mdi-floppy
        </v-icon>
        {{ $lang.actions.save }}
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({ code: '' }),
  created() {
    if (this.item) this.code = this.item.value
  },
  methods: {
    save() {
      this.code = this.code.replaceAll(/\n/g, ' ')
      this.$emit('fromEditor', { text: this.item.text, value: this.code, parentType: this.item.parentType })
      this.$emit('closeDialog', true)
    }
  }
}
</script>
<style lang="scss" scoped>
/* required class */
@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}

.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 76vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
