<template>
  <v-card class="pa-2" style="min-height: 90vh; max-height: 90vh; overflow-y: hidden">
    <v-card-title class="pb-0">
      <v-row class="mx-0 pb-1 align-center" justify="space-between" style="height: 100px">
        <span>{{ $lang.labels.code }}</span>
        <div class="d-inline-flex">
          <v-autocomplete
            v-if="['SWITCH', 'QUERY', 'JS', 'PYTHON', 'GROOVY'].includes(step.type) || step.subType === 'QUERY'"
            v-model="customJSFunctionsValue"
            :items="customJSFunctions"
            :label="$lang.labels.customFunctions"
            outlined
            dense
            hide-details
            hide-no-data
            hide-selected
            auto
            style="max-width: 300px"
            class="ml-2"
            @change="addShortCode(customJSFunctionsValue)"
          />
          <v-autocomplete
            v-if="step.type === 'JDBC'"
            v-model="customFunctionsValue"
            :items="customFunctions"
            :label="$lang.labels.customFunctions"
            outlined
            dense
            hide-details
            hide-no-data
            hide-selected
            auto
            style="max-width: 300px"
            class="ml-2"
            @change="addShortCode(customFunctionsValue)"
          />
          <v-autocomplete
            v-if="step.type === 'MONGODB'"
            v-model="customFunctionsValue"
            :items="customQueryFunction"
            :label="$lang.labels.customFunctions"
            outlined
            dense
            hide-details
            hide-no-data
            hide-selected
            auto
            style="max-width: 300px"
            class="ml-2"
            @change="addShortCode(customFunctionsValue)"
          />
          <v-autocomplete
            v-model="valueArrayValue"
            :items="valueArray"
            item-text="key"
            item-value="key"
            label="Variables"
            outlined
            dense
            hide-details
            hide-no-data
            hide-selected
            auto
            style="max-width: 300px"
            class="ml-2"
            @change="addShortCode(valueArrayValue)"
          />
          <v-btn
            icon
            color="primary"
            text
            large
            class="ml-2"
            @click="$emit('closeDialog', true)"
          >
            <v-icon dark size="20" >mdi-close</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-card-title>
    <prism-editor
      v-if="language !== 'JS'"
      v-model="code"
      class="my-editor"
      :highlight="highlighter"
      line-numbers
      @blur="handleBlur"
    />
    <eslint-editor
      v-else
      v-model="code"
      class="my-editor"
      :highlight="highlighter"
      :linter="linter"
      :config="config"
      dark
      @blur="handleBlurEditor"
    />
    <v-card-title class="pt-2">
      <v-btn
        color="primary"
        outlined
        class="ml-1 mb-1"
        min-width="100px"
        min-height="40px"
        @click="$emit('closeDialog', true)"
      >
        <v-icon left dark class="mr-1">
          mdi-cancel
        </v-icon>
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        class="mr-1 mb-1"
        min-width="100px"
        min-height="40px"
        @click="save()"
      >
        <v-icon left dark class="mr-1">
          mdi mdi-floppy
        </v-icon>
        {{ $lang.actions.save }}
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css'
import EslintEditor from 'vue-eslint-editor' // import syntax highlighting styles

import { defaultRestVariables } from '@/utils/constants'
import { recursion } from '@/utils/helpers'

export default {
  components: {
    EslintEditor,
    PrismEditor
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    language: {
      type: String
    },
    vars: {
      type: Array,
      default: () => {
        return []
      }
    },
    step: {
      type: Object,
      default: () => {
        return {}
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    cursorPosition: 0,
    valueArray: [],
    dialog: false,
    code: null,
    linter: null,
    config: {
      globals: {
        // ES2015 globals
        ArrayBuffer: false,
        DataView: false,
        Float32Array: false,
        Float64Array: false,
        Int16Array: false,
        Int32Array: false,
        Int8Array: false,
        Map: false,
        Promise: false,
        Proxy: false,
        Reflect: false,
        Set: false,
        Symbol: false,
        Uint16Array: false,
        Uint32Array: false,
        Uint8Array: false,
        Uint8ClampedArray: false,
        WeakMap: false,
        WeakSet: false
      },
      rules: {
        // eslint:recommended rules
        'constructor-super': 'error',
        'no-case-declarations': 'error',
        'no-class-assign': 'error',
        'no-compare-neg-zero': 'error',
        'no-cond-assign': 'error',
        'no-console': 'error',
        'no-const-assign': 'error',
        'no-constant-condition': 'error',
        'no-control-regex': 'error',
        'no-debugger': 'error',
        'no-delete-var': 'error',
        'no-dupe-args': 'error',
        'no-dupe-class-members': 'error',
        'no-dupe-keys': 'error',
        'no-duplicate-case': 'error',
        'no-empty': 'error',
        'no-empty-character-class': 'error',
        'no-empty-pattern': 'error',
        'no-ex-assign': 'error',
        'no-extra-boolean-cast': 'error',
        'no-extra-semi': 'error',
        'no-fallthrough': 'error',
        'no-func-assign': 'error',
        'no-global-assign': 'error',
        'no-inner-declarations': 'error',
        'no-invalid-regexp': 'error',
        'no-irregular-whitespace': 'error',
        'no-mixed-spaces-and-tabs': 'error',
        'no-new-symbol': 'error',
        'no-obj-calls': 'error',
        'no-octal': 'error',
        'no-redeclare': 'error',
        'no-regex-spaces': 'error',
        'no-self-assign': 'error',
        'no-sparse-arrays': 'error',
        'no-this-before-super': 'error',
        'no-undef': 'error',
        'no-unexpected-multiline': 'error',
        'no-unreachable': 'error',
        'no-unsafe-finally': 'error',
        'no-unsafe-negation': 'error',
        'no-unused-labels': 'error',
        'no-unused-vars': 'error',
        'no-useless-escape': 'error',
        'require-yield': 'error',
        'use-isnan': 'error',
        'valid-typeof': 'error',
        // a few style rules.
        semi: 'error',
        quotes: 'error',
        indent: ['error', 4]
      },
      parserOptions: {
        // ecmaVersion: 2015,
        // sourceType: 'module',
        globalReturn: true,
        ecmaFeatures: {
          jsx: true,
          globalReturn: true
        }
      }
    },
    customFunctions: [
      'f_array',
      'f_list',
      'f_query'
    ],
    customJSFunctions: [
      'f_is_var_defined',
      'f_is_var_defined_and_not_null',
      'f_check_defined_and_not_null_default'
    ],
    customQueryFunction: [
      'f_query'
    ],
    customJSFunctionsValue: '',
    customFunctionsValue: '',
    valueArrayValue: ''
  }),
  created() {
    if (this.item) {
      this.code = this.language === 'JS' ? '/*global $*/' + '\n' + this.item.value : this.item.value
    }

    this.cursorPosition = this.code ? this.code.length : 0

    this.valueArray = this.recursion(this.vars, this.singleStep)

    const names = this.valueArray.map((o) => o.key)
    const filtered = this.valueArray.filter(({ key }, index) => !names.includes(key, index + 1))

    this.valueArray = filtered.sort((a, b) => a.key.localeCompare(b.key))

    this.valueArray = [...this.valueArray, ...defaultRestVariables]
  },
  async mounted() {
    // Load linter.
    const { default: Linter } = await import('eslint4b')

    this.linter = new Linter()
  },
  methods: {
    recursion,
    handleBlurEditor(data) {
      console.log(data)
    },
    handleBlur(event) {
      this.cursorPosition = event.target.selectionStart
    },
    addShortCode(value) {
      if (!this.code) this.code = ''

      const position = this.cursorPosition === 0 || this.cursorPosition ? this.cursorPosition : this.code.length ? this.code.length : 0

      if (this.code.length === 0) {
        this.code = this.code + value
      } else if (this.code.length === position) {
        this.code = this.code + value
      } else {
        this.code = this.code.slice(0, position) + value + this.code.slice(position)
      }

      setTimeout(() => {
        this.customJSFunctionsValue = null
        this.customFunctionsValue = ''
        this.valueArrayValue = ''
      }, 5)
    },
    keyPressed(e) {
      if (e.ctrlKey && e.code === 'Space') {
        this.dialog = true
      }
    },
    highlighter(code) {
      return highlight(code, languages.js) //returns html
    },
    save() {
      this.$emit('fromEditor', { text: this.item.text, value: this.language === 'JS' ? this.code.replace('/*global $*/', '').replace('\n', '') : this.code, parentType: this.item.parentType })
      setTimeout(() => {
        this.$emit('closeDialog', true)
      }, 5)
    }
  }
}
</script>
<style lang="scss" scoped>

@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 71vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
